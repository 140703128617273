import Hero from 'components/Hero';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { RootState } from 'redux/store';

type ContextType = { prevPath: string | null; setPrevPath: (prevPath: string | null) => void };

const Layout = () => {
    const [prevPath, setPrevPath] = useState<string | null>(null);
    const authState = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (authState.access_token) {
            navigate('/');
        }
    }, [authState.access_token, navigate]);

    return (
        <div className="w-screen h-dvh bg-smoke bg-cover bg-center flex flex-col items-center justify-evenly">
            <div className="h-full w-full md:w-10/12 lg:w-7/12 xl:w-5/12 font-['SF-Pro-Display'] tracking-wide flex flex-col">
                <div className="h-1/4 flex items-center">
                    <Hero prevPath={prevPath} />
                </div>
                <div className="w-full h-3/4 flex flex-col items-center justify-between">
                    <div className="w-full flex items-center h-full">
                        <Outlet context={{ prevPath, setPrevPath } satisfies ContextType} />
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="divider w-full"></div>
                        <div className="text-center text-xs w-2/3 pb-4">
                            <p>
                                By sharing your email, you agree to our{' '}
                                <a href="https://www.google.com" className="text-secondary underline">
                                    Terms of Service
                                </a>
                                ,{' '}
                                <a href="https://www.google.com" className="text-secondary underline">
                                    Privacy Policy
                                </a>
                                , and{' '}
                                <a href="https://www.google.com" className="text-secondary underline">
                                    Medical Disclaimer
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export function useReturnPath() {
    return useOutletContext<ContextType>();
}

export default Layout;
