import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { completeBilling, createBilling, updateProfilePicture, updateUser } from './actions/userActions';

export interface UserState {
    clientSecret?: string;
    subscriptionId?: string;
    loading: boolean;
    error: string | null;
}

const initialState: UserState = {
    loading: false,
    error: null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(createBilling.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(createBilling.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                console.log(action.payload)
                state.subscriptionId = action.payload.results.subscriptionId
                state.clientSecret = action.payload.results.clientSecret
                state.error = null
            })
            .addCase(createBilling.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(completeBilling.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(completeBilling.fulfilled, (state) => {
                state.loading = false
                state.error = null
            })
            .addCase(completeBilling.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(updateUser.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                console.log("IMPLEMENT", action)
                state.error = null
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(updateProfilePicture.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(updateProfilePicture.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                console.log("updateProfilePicture", action)
                state.error = null
            })
            .addCase(updateProfilePicture.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    }
});



export const { } = userSlice.actions;

export default userSlice.reducer;
