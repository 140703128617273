import { useGoogleLogin } from '@react-oauth/google';
import AppleLogo from 'assets/img/icons/apple-logo.png';
import GoogleLogo from 'assets/img/icons/google-logo.png';
import config from 'config/config';
import { useCallback, useEffect } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { oauthLogin } from '../../redux/actions/authActions';
import { useReturnPath } from './layout';

const Login = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { setPrevPath } = useReturnPath();

    useEffect(() => {
        setPrevPath(null);
    });

    const handleGoogleSuccess = useCallback(
        (response) => {
            console.log(response);
            dispatch(oauthLogin({ provider: 'google', token: response.accessToken }));
        },
        [dispatch]
    );

    const handleGoogleFailure = useCallback(() => {
        console.log('failed');
    }, []);

    const handleAppleSuccess = useCallback((response) => {
        console.log(response);
    }, []);

    const handleAppleFailure = useCallback((error) => {
        console.log(error);
    }, []);

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: (response) => handleGoogleSuccess(response),
        onError: () => handleGoogleFailure(),
    });

    return (
        <div className="flex flex-col justify-center items-center w-full h-full">
            <div className="flex flex-col justify-around w-10/12 space-y-2">
                <p className="text-center font-black tracking-wide">Designed to keep you consistent</p>
                <Link to={'/auth/signin'} className="btn hover:btn-secondary btn-outline rounded-full ">
                    Sign In
                </Link>
                <Link to={'/auth/signup'} className="btn hover:btn-secondary btn-outline rounded-full">
                    Create Account
                </Link>
            </div>
            <div className="flex w-full justify-center">
                <div className="divider divider-secondary opacity-50 rounded-full w-10/12"></div>
            </div>
            <div className="flex flex-col justify-around w-10/12 space-y-2">
                <AppleSignin
                    authOptions={{
                        clientId: config.oauth_apple_client_id,
                        scope: 'email name',
                        redirectURI: window.location.origin,
                    }}
                    uiType="dark"
                    render={(props) => (
                        <button {...props} className="btn hover:btn-secondary btn-outline rounded-full">
                            <img className="w-4 mx-2" src={AppleLogo} alt="apple-logo" />
                            Continue With Apple
                        </button>
                    )}
                    onSuccess={handleAppleSuccess}
                    onError={handleAppleFailure}
                />
                <button
                    onClick={() => handleGoogleLogin()}
                    className="btn hover:btn-secondary btn-outline rounded-full"
                >
                    <img className="w-4 mx-2" src={GoogleLogo} alt="google-logo" />
                    Continue With Google
                </button>
            </div>
        </div>
    );
};

export default Login;
