import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authMiddleware from 'util/middleware';
import authReducer, { AuthState } from './authSlice';
import programReducer, { ProgramState } from './programSlice';
import stripeReducer, { StripeState } from './stripeSlice';
import trainerReducer, { TrainerState } from './trainerSlice';
import userReducer, { UserState } from './userSlice';
import workoutReducer, { WorkoutState } from './workoutSlice';

export type RootState = {
  auth: AuthState,
  workouts: WorkoutState,
  programs: ProgramState,
  trainers: TrainerState,
  user: UserState,
  stripe: StripeState,
}

const rootReducer = combineReducers({
  auth: authReducer,
  workouts: workoutReducer,
  programs: programReducer,
  trainers: trainerReducer,
  user: userReducer,
  stripe: stripeReducer,
})

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
      ignoredPaths: ['items.dates'],
    },
  }).concat(authMiddleware)
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export { persistor, store };
