import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCurrentUser, login, refreshToken, registerUser } from './actions/authActions';

export interface User {
  user_id: string,
  email: string,
  user_email: string,
  user_username: string,
  user_name: string,
  profile_image: string
}

export interface NewUser {
  full_name: string;
  username: string;
  password: string;
  email: string;
  date_of_birth: string;
  gender: string,
  goal: string,
  activity_level: string,
  weight: string,
  height: string,
  profile_picture: string,
  stripe_id: string,
  purpose: string,
  workout_length: string
}

export interface AuthState {
  access_token: string
  email_verified: boolean
  refresh_token: string
  currentUser?: User
  newUser?: NewUser
  loading: boolean
  error?: string
}

const initialState: AuthState = {
  access_token: '',
  email_verified: false,
  refresh_token: '',
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens(state, action: PayloadAction<any>) {
      state.access_token = action.payload.access_token
      state.refresh_token = action.payload.refresh_token
      state.email_verified = action.payload.email_verified
    },
    logout: (state) => {
      state.access_token = null;
      state.email_verified = null;
      state.refresh_token = null;
      state.error = null;
    },
    setNewUser: (state, action: PayloadAction<Partial<User>>) => {
      state.newUser = {
        ...state.newUser,
        ...action.payload
      };
    },
    resetNewUser: (state) => {
      state.newUser = null
    },
    clearAuthState: (state) => {
      state.access_token = initialState.access_token
      state.email_verified = initialState.email_verified
      state.refresh_token = initialState.refresh_token
      state.currentUser = initialState.currentUser
      state.newUser = initialState.newUser
      state.loading = initialState.loading
      state.error = initialState.error
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.email_verified = action.payload.email_verified
        state.access_token = action.payload.access_token
        state.refresh_token = action.payload.refresh_token
        state.error = null
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = null
      })
      .addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.currentUser = action.payload
        state.error = null
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(refreshToken.pending, (state) => {
        state.loading = true;
        state.error = null
      })
      .addCase(refreshToken.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.currentUser = action.payload
        state.error = null
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null
      })
      .addCase(registerUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = null
        state.access_token = action.payload.access_token
        state.refresh_token = action.payload.refresh_token

      })
      .addCase(registerUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setTokens, logout, setNewUser, resetNewUser, clearAuthState } = authSlice.actions;

export default authSlice.reducer;
