import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import BackIcon from '../../assets/img/icons/back-icon.png';
import { getWorkoutById, getWorkoutVideos } from '../../redux/actions/workoutActions';
import { selectVideo } from '../../redux/workoutSlice';

const WorkoutPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { id } = useParams();
    const { loading, loadingVideos, workout: current } = useSelector((state: RootState) => state.workouts);

    const handleSelectVideo = useCallback(
        (index: number) => {
            dispatch(selectVideo(index));
            navigate(`/workout/${id}/video`);
        },
        [dispatch, id, navigate]
    );

    const handleBack = () => {
        navigate('/');
    };

    useEffect(() => {
        if (!current || current.id.toString() !== id) {
            dispatch(getWorkoutById({ id }));
            dispatch(getWorkoutVideos({ id }));
        }
    }, [current, dispatch, id]);

    return (
        <div className="w-full h-full mb-16 font-['Poppins-Med']">
            <div className="h-2/6 flex flex-col justify-evenly">
                <div className="h-2/6 w-full p-4 flex relative">
                    <button onClick={handleBack} className="absolute top-4 font-black text-white p-2">
                        <img src={BackIcon} alt="Back Icon" className="h-4" />
                    </button>
                    <div className="w-full flex justify-center items-center">
                        <p className="w-fit rounded px-2 h-fit text-white text-xl">Workout</p>
                    </div>
                </div>
                <div className="w-full h-4/6 md:h-2/3">
                    {loading ? (
                        <div className="skeleton bg-secondary/20 rounded-none h-full w-full"></div>
                    ) : (
                        <div className="relative flex flex-col h-full justify-end items-center p-4">
                            <img
                                src={current?.workout_cover_image}
                                alt="workout"
                                className="absolute inset-0 w-full object-cover object-[center_30%] h-full"
                            />
                            <div className="bg-gradient-to-t from-black to-transparent opacity-50 w-full h-full inset-0 absolute z-0" />
                            <p className="text-2xl font-bold text-center text-white uppercase z-10">{current?.title}</p>
                            <button
                                onClick={() => handleSelectVideo(0)}
                                className="btn btn-circle btn-outline btn-wide bg-black/75 text-white tracking-wider font-light z-10"
                            >
                                Start Now
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="h-4/6 flex flex-col justify-evenly">
                <div className="w-full flex justify-evenly lg:h-auto p-2">
                    <div className="flex flex-col items-center justify-center p-2">
                        <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.5 0H12.5V6L8.5 10L12.5 14V20H0.5V14L4.5 10L0.5 6V0ZM10.5 14.5L6.5 10.5L2.5 14.5V18H10.5V14.5ZM6.5 9.5L10.5 5.5V2H2.5V5.5L6.5 9.5ZM4.5 4H8.5V4.75L6.5 6.75L4.5 4.75V4Z"
                                fill="#05BEF8"
                            />
                        </svg>
                        <p className="text-xs">Duration</p>
                        <p className="text-xs">{loading ? '...' : (current?.workout_duration % 60) + ' min'}</p>
                    </div>
                    <div className="divider divider-horizontal" />
                    <div className="flex flex-col items-center justify-center p-2">
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.1318 15.3196L15.6472 16.7882C16.6076 15.5626 17.1946 14.1469 17.376 12.6783H15.2203C15.0709 13.5975 14.7081 14.4955 14.1318 15.3196ZM9.9805 17.8553V19.9894C11.4639 19.8098 12.9045 19.2393 14.1425 18.2884L12.6057 16.767C11.8054 17.3376 10.9089 17.7073 9.9805 17.8553ZM17.376 10.5652C17.1859 9.06567 16.5884 7.64525 15.6472 6.45536L14.1318 7.95563C14.7081 8.74802 15.0709 9.64606 15.2203 10.5652M12.7018 4.80718L7.84616 0V3.24353C3.64151 3.76123 0.375977 7.31115 0.375977 11.6218C0.375977 15.9324 3.63084 19.4823 7.84616 20V17.8658C4.8154 17.3587 2.51031 14.7596 2.51031 11.6218C2.51031 8.48389 4.8154 5.88484 7.84616 5.37771V9.50872L12.7018 4.80718Z"
                                fill="#05BEF8"
                            />
                        </svg>
                        <p className="text-xs">Rounds</p>
                        <p className="text-xs">{current?.rounds ?? '...'}</p>
                    </div>
                    <div className="divider divider-horizontal" />
                    <div className="flex flex-col items-center justify-center p-2">
                        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.252 12.7272C16.252 17.02 12.8941 20.4999 8.75195 20.4999C4.60982 20.4999 1.25195 17.02 1.25195 12.7272C1.25195 11.1614 1.71266 8.5397 3.12695 7.19994L5.52536 10.4405C5.65319 10.6132 5.93688 10.5206 5.94073 10.3058C5.97555 8.36646 6.2955 2.93882 8.75195 1.49994C8.75195 1.49994 16.252 8.43445 16.252 12.7272Z"
                                stroke="#05BEF8"
                                strokeWidth="2"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <p className="text-xs">Difficulty</p>
                        <p className="text-xs">{current?.difficulty ?? '...'}</p>
                    </div>
                </div>
                <div className="w-full md:h-auto flex flex-col justify-center p-[1rem]">
                    <div className="text-lg font-medium">Description:</div>
                    <div className="h-full">
                        {loading ? (
                            <p className="skeleton bg-secondary/25 h-full w-full"></p>
                        ) : (
                            <p className="text-xs font-extralight">{current?.description}</p>
                        )}
                    </div>
                </div>
                <div className="w-full h-full flex flex-col justify-center">
                    <p className="font-medium text-lg px-[1rem] pt-[1rem]">Videos:</p>
                    <div className="carousel h-full w-full">
                        {loadingVideos ? (
                            <div className="h-full w-full pl-4 flex">
                                <div className="skeleton bg-secondary/25 w-2/3 h-full"></div>
                                <div className="skeleton ml-4 bg-secondary/10 w-2/3 h-full"></div>
                            </div>
                        ) : (
                            current?.videos?.map((video, index) => (
                                <div key={index} className="carousel-item w-2/3 lg:w-1/6 rounded-full cursor-pointer">
                                    <div onClick={() => handleSelectVideo(index)} className="card relative p-4">
                                        <div className="absolute h-full inset-0 bg-gradient-to-t from-black from-20% to-60% to-transparent rounded"></div>
                                        <img
                                            className="w-full rounded"
                                            src={video?.video_thumbnail_image}
                                            alt="workout"
                                        />
                                        <div className="card-body p-2 absolute text-white bottom-0">
                                            <p className="text-sm">{video.title}</p>
                                            <p className="flex font-bold text-lg capitalize">
                                                <svg
                                                    width="25"
                                                    height="24"
                                                    viewBox="0 0 25 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12.4996 12C10.0326 12 8.0166 9.985 8.0166 7.517C8.0166 5.049 10.0326 3 12.4996 3C14.9666 3 16.9826 5.015 16.9826 7.483C16.9826 9.951 14.9666 12 12.4996 12Z"
                                                        fill="#8E8E8E"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M19.5 21H5.5C4.95 21 4.5 20.55 4.5 20V19C4.5 16.8 6.3 15 8.5 15H16.5C18.7 15 20.5 16.8 20.5 19V20C20.5 20.55 20.05 21 19.5 21Z"
                                                        fill="#8E8E8E"
                                                    />
                                                </svg>
                                                {video.trainer_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkoutPage;
