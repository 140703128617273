import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import VideoCarousel from './VideoCarousel';

const VideoControls = (props: {
    showControls: boolean;
    setShowControls: Dispatch<SetStateAction<boolean>>;
    controlsCountDown: number;
    currentVideoIndex: number;
    progress: number;
    currentTime: number;
    play: boolean;
    setPlay: React.Dispatch<React.SetStateAction<boolean>>;
    handleBackward: () => void;
    handleForward: () => void;
    handleLastVideo: () => void;
    handleNextVideo: () => void;
}) => {
    const {
        workout: { videos },
    } = useSelector((state: RootState) => state.workouts);
    const {
        showControls,
        currentVideoIndex,
        progress,
        currentTime,
        play,
        setPlay,
        handleBackward,
        handleForward,
        handleLastVideo,
        handleNextVideo,
    } = props;

    return (
        <div className={`absolute w-full h-dvh inset-0 ${showControls ? '' : 'hidden'}`}>
            <div className="w-full h-full flex flex-col justify-end">
                <div className="w-full inset-0 bg-gradient-to-t from-black via-transparent via-80% to-black/50 absolute opacity-60"></div>
                <div className="w-full h-1/2 flex flex-col items-center justify-center z-20">
                    <VideoCarousel videos={videos} nextVideoIndex={currentVideoIndex + 1} />
                    <div className="w-10/12">
                        <progress
                            className="progress drop-shadow progress-secondary w-full h-2"
                            value={progress.toFixed(2)}
                            max="100"
                        ></progress>
                    </div>
                    <div className="flex w-10/12">
                        <p className="text-white">{`${Math.floor(currentTime / 60)
                            .toString()
                            .padStart(2, '0')}:${Math.floor(currentTime % 60)
                            .toString()
                            .padStart(2, '0')}`}</p>
                    </div>
                    <div className="flex justify-evenly w-10/12">
                        <button onClick={handleLastVideo} disabled={currentVideoIndex - 1 < 0}>
                            <svg className="w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path
                                    d="M9.31624 12.8319C8.7227 12.4361 8.72269 11.5639 9.31624 11.168L17.0101 6.03692C17.6746 5.59373 18.5649 6.07011 18.5649 6.86888V17.1311C18.5649 17.9299 17.6746 18.4062 17.0101 17.9631L9.31624 12.8319Z"
                                    fill="white"
                                />
                                <rect
                                    width="1.9084"
                                    height="14"
                                    rx="0.954198"
                                    transform="matrix(-1 0 0 1 7.11475 5)"
                                    fill="white"
                                />
                            </svg>
                        </button>
                        <button onClick={handleBackward}>
                            <svg className="w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path
                                    d="M11.0667 6.76602C11.0667 7.26915 10.5157 7.55804 10.1263 7.25905L6.12082 4.18324C5.80173 3.93821 5.80172 3.44223 6.1208 3.19719L10.1263 0.121098C10.5157 -0.177911 11.0667 0.110977 11.0667 0.614119V2.05953C11.0667 2.12449 11.1158 2.17812 11.1782 2.1826C16.3888 2.55676 20.504 7.06829 20.504 12.5787C20.504 18.3342 16.0147 23 10.4768 23C5.1372 23 0.772395 18.6623 0.466768 13.1924C0.447882 12.8544 0.713767 12.5793 1.03954 12.5793H2.80905C3.13479 12.5793 3.39629 12.8547 3.42309 13.1921C3.72297 16.9678 6.7665 19.9349 10.4768 19.9349C14.3859 19.9349 17.5548 16.6415 17.5548 12.5787C17.5548 8.76806 14.7664 5.63413 11.1946 5.25999C11.126 5.2528 11.0667 5.30925 11.0667 5.38094V6.76602Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.4393 15.3486C11.7644 15.5698 12.1515 15.6804 12.6006 15.6804C13.0513 15.6804 13.4384 15.5707 13.7618 15.3513C14.0869 15.1301 14.3374 14.8105 14.5133 14.3925C14.6893 13.9746 14.7772 13.4713 14.7772 12.8826C14.7789 12.2975 14.6918 11.7994 14.5158 11.3884C14.3399 10.9774 14.0886 10.6648 13.7618 10.4506C13.4367 10.2347 13.0496 10.1267 12.6006 10.1267C12.1515 10.1267 11.7644 10.2347 11.4393 10.4506C11.1159 10.6648 10.8663 10.9774 10.6903 11.3884C10.516 11.7977 10.4281 12.2958 10.4264 12.8826C10.4247 13.4695 10.5119 13.972 10.6878 14.3899C10.8637 14.8062 11.1143 15.1257 11.4393 15.3486ZM13.2516 14.1966C13.0857 14.4805 12.8687 14.6224 12.6006 14.6224C12.4229 14.6224 12.2663 14.5597 12.1305 14.4343C11.9948 14.3072 11.8892 14.1148 11.8138 13.857C11.7401 13.5993 11.7041 13.2745 11.7058 12.8826C11.7074 12.3045 11.7904 11.876 11.9546 11.5974C12.1188 11.317 12.3341 11.1768 12.6006 11.1768C12.7799 11.1768 12.9365 11.2387 13.0706 11.3623C13.2046 11.4859 13.3094 11.674 13.3848 11.9266C13.4602 12.1773 13.4979 12.496 13.4979 12.8826C13.4996 13.473 13.4174 13.911 13.2516 14.1966Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M8.50789 15.5498C8.57304 15.5498 8.62585 15.4949 8.62585 15.4272V10.3224C8.62585 10.2547 8.57304 10.1998 8.50789 10.1998H7.50584C7.48383 10.1998 7.46225 10.2062 7.44355 10.2183L6.25852 10.9841C6.2239 11.0065 6.20285 11.0459 6.20285 11.0882V11.9195C6.20285 12.0152 6.30374 12.074 6.38234 12.0241L7.34837 11.4103C7.35207 11.408 7.35632 11.4067 7.36065 11.4067C7.37365 11.4067 7.38419 11.4177 7.38419 11.4312V15.4272C7.38419 15.4949 7.437 15.5498 7.50215 15.5498H8.50789Z"
                                    fill="#F5F5F5"
                                />
                            </svg>
                        </button>
                        <label className="swap">
                            <input type="checkbox" className="hidden" checked={play} onChange={() => setPlay(!play)} />
                            <svg
                                className="swap-off w-16"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 64 64"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM44.905 31.1562C45.5229 31.5492 45.5229 32.451 44.905 32.8439L25.5366 45.1599C24.8708 45.5832 24 45.105 24 44.316V19.6842C24 18.8952 24.8708 18.417 25.5366 18.8403L44.905 31.1562Z"
                                    fill="white"
                                />
                            </svg>
                            <svg
                                className="swap-on w-16"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 64 64"
                            >
                                <path
                                    d="M23.2 44.8H28V19.2H23.2V44.8ZM36 44.8H40.8V19.2H36V44.8ZM32 64C27.6267 64 23.4933 63.16 19.6 61.48C15.7067 59.8 12.3067 57.5067 9.4 54.6C6.49333 51.6933 4.2 48.2933 2.52 44.4C0.84 40.5067 0 36.3733 0 32C0 27.5733 0.84 23.4133 2.52 19.52C4.2 15.6267 6.49333 12.24 9.4 9.36C12.3067 6.48 15.7067 4.2 19.6 2.52C23.4933 0.84 27.6267 0 32 0C36.4267 0 40.5867 0.84 44.48 2.52C48.3733 4.2 51.76 6.48 54.64 9.36C57.52 12.24 59.8 15.6267 61.48 19.52C63.16 23.4133 64 27.5733 64 32C64 36.3733 63.16 40.5067 61.48 44.4C59.8 48.2933 57.52 51.6933 54.64 54.6C51.76 57.5067 48.3733 59.8 44.48 61.48C40.5867 63.16 36.4267 64 32 64ZM32 59.2C39.5733 59.2 46 56.5467 51.28 51.24C56.56 45.9333 59.2 39.52 59.2 32C59.2 24.4267 56.56 18 51.28 12.72C46 7.44 39.5733 4.8 32 4.8C24.48 4.8 18.0667 7.44 12.76 12.72C7.45333 18 4.8 24.4267 4.8 32C4.8 39.52 7.45333 45.9333 12.76 51.24C18.0667 56.5467 24.48 59.2 32 59.2Z"
                                    fill="white"
                                />
                            </svg>
                        </label>
                        <button onClick={handleForward}>
                            <svg className="w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path
                                    d="M10.2235 6.76602C10.2235 7.26915 10.7744 7.55804 11.1638 7.25905L15.1693 4.18324C15.4884 3.93821 15.4884 3.44223 15.1693 3.19719L11.1638 0.121098C10.7745 -0.177911 10.2235 0.110977 10.2235 0.614119V2.05953C10.2235 2.12449 10.1743 2.17812 10.1119 2.1826C4.90132 2.55676 0.786133 7.06829 0.786133 12.5787C0.786133 18.3342 5.27545 23 10.8133 23C16.1529 23 20.5177 18.6623 20.8234 13.1924C20.8422 12.8544 20.5764 12.5793 20.2506 12.5793H18.4811C18.1553 12.5793 17.8938 12.8547 17.867 13.1921C17.5672 16.9678 14.5236 19.9349 10.8133 19.9349C6.90422 19.9349 3.7353 16.6415 3.7353 12.5787C3.7353 8.76806 6.52377 5.63413 10.0955 5.25999C10.1642 5.2528 10.2235 5.30925 10.2235 5.38094V6.76602Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.7758 15.3486C12.1009 15.5698 12.4879 15.6804 12.937 15.6804C13.3878 15.6804 13.7749 15.5707 14.0983 15.3513C14.4233 15.1301 14.6738 14.8105 14.8498 14.3925C15.0257 13.9746 15.1137 13.4713 15.1137 12.8826C15.1154 12.2975 15.0283 11.7994 14.8523 11.3884C14.6764 10.9774 14.425 10.6648 14.0983 10.4506C13.7732 10.2347 13.3861 10.1267 12.937 10.1267C12.4879 10.1267 12.1009 10.2347 11.7758 10.4506C11.4524 10.6648 11.2027 10.9774 11.0268 11.3884C10.8525 11.7977 10.7645 12.2958 10.7629 12.8826C10.7612 13.4695 10.8483 13.972 11.0243 14.3899C11.2002 14.8062 11.4507 15.1257 11.7758 15.3486ZM13.588 14.1966C13.4221 14.4805 13.2051 14.6224 12.937 14.6224C12.7594 14.6224 12.6027 14.5597 12.467 14.4343C12.3313 14.3072 12.2257 14.1148 12.1503 13.857C12.0766 13.5993 12.0405 13.2745 12.0422 12.8826C12.0439 12.3045 12.1268 11.876 12.2911 11.5974C12.4553 11.317 12.6706 11.1768 12.937 11.1768C13.1163 11.1768 13.273 11.2387 13.407 11.3623C13.5411 11.4859 13.6458 11.674 13.7212 11.9266C13.7966 12.1773 13.8343 12.496 13.8343 12.8826C13.836 13.473 13.7539 13.911 13.588 14.1966Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M8.84435 15.5498C8.9095 15.5498 8.96231 15.4949 8.96231 15.4272V10.3224C8.96231 10.2547 8.9095 10.1998 8.84435 10.1998H7.84231C7.82029 10.1998 7.79871 10.2062 7.78002 10.2183L6.59498 10.9841C6.56036 11.0065 6.53931 11.0459 6.53931 11.0882V11.9195C6.53931 12.0152 6.6402 12.074 6.71881 12.0241L7.68484 11.4103C7.68853 11.408 7.69278 11.4067 7.69711 11.4067C7.71011 11.4067 7.72065 11.4177 7.72065 11.4312V15.4272C7.72065 15.4949 7.77346 15.5498 7.83862 15.5498H8.84435Z"
                                    fill="#F5F5F5"
                                />
                            </svg>
                        </button>
                        <button onClick={handleNextVideo} disabled={currentVideoIndex === videos.length - 1}>
                            <svg className="w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path
                                    d="M14.9279 12.8319C15.5214 12.4361 15.5214 11.5639 14.9279 11.168L7.23404 6.03692C6.5695 5.59373 5.6792 6.07011 5.6792 6.86888V17.1311C5.6792 17.9299 6.5695 18.4062 7.23404 17.9631L14.9279 12.8319Z"
                                    fill="white"
                                />
                                <rect x="17.1294" y="5" width="1.9084" height="14" rx="0.954199" fill="white" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoControls;
