import { createAsyncThunk } from '@reduxjs/toolkit';
import { connection } from 'util/axios';

export const createBilling = createAsyncThunk(
    'user/create-billing',
    async (props: { priceId: string }, { rejectWithValue }) => {
        try {
            const { priceId } = props;
            const response = await connection.post('/user/create-billing', { priceId });

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const completeBilling = createAsyncThunk(
    'user/complete-billing',
    async (_, { rejectWithValue }) => {
        try {
            const response = await connection.post('/user/complete-billing');
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const updateProfilePicture = createAsyncThunk(
    'user/picture-update',
    async (props: { file: File }, { rejectWithValue }) => {
        try {
            const { file } = props;
            const formData = new FormData();
            formData.append('file', file);

            const response = await connection.post('/user/picture/update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);


export const updateUser = createAsyncThunk(
    'user/update',
    async (props: {
        id: string,
        data: {
            name?: string,
            bio?: string,
            location?: string,
            gender?: string,
            dob?: string,
            weight?: string,
            height?: string,
            phone?: string,
            activity?: string,
            username?: string,
            new_password?: false,
            private_profile?: false,
            image?: string,
            image_type?: string,
        }
    }, { rejectWithValue }) => {
        try {
            const { id, data } = props
            const response = await connection.post(`/user/update/${id}`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);