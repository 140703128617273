import { useEffect, useState } from 'react';

const WarningNotification = (props: { error: string }) => {
    const { error } = props;
    const [showError, setShowError] = useState<boolean>(false);

    useEffect(() => {
        if (error) {
            setShowError(true);
            const timer = setTimeout(() => {
                setShowError(false);
            }, 3000); // Hide the error after 3 seconds
            return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
    }, [error]);

    return (
        showError &&
        error && (
            <div className="toast toast-top toast-center">
                <div className="alert alert-warning border-2 border-black">
                    <span className="text-xs font-extralight">{error}</span>
                </div>
            </div>
        )
    );
};

export default WarningNotification;
