import BottomNav from 'components/BottomNav';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <div className='w-full h-dvh flex flex-col items-center justify-between font-["SF-Pro-Display"]'>
            <Outlet />
            <BottomNav />
        </div>
    );
};

export default Layout;
