import CloseIcon from 'assets/img/icons/CloseIcon';
import VideoControls from 'components/VideoControls';
import VideoCountDown from 'components/VideoCountDown';
import VideoPlayer from 'components/VideoPlayer';
import Hls from 'hls.js';
import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { selectVideo } from '../../redux/workoutSlice';

const WorkoutVideoPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentVideoIndex = useSelector((state: RootState) => state.workouts.currentVideoIndex, shallowEqual);
    const id = useSelector((state: RootState) => state.workouts.workout.id, shallowEqual);
    const videos = useSelector((state: RootState) => state.workouts.workout.videos, shallowEqual);
    const videoElementsRef = useRef<React.RefObject<HTMLVideoElement>[]>(
        videos.map(() => createRef<HTMLVideoElement>())
    );
    const hlsRef = useRef<Hls | null>();
    const prevHlsRef = useRef<Hls | null>();
    const [showCountDown, setShowCountDown] = useState<boolean>(true);
    const [play, setPlay] = useState<boolean>(false);
    const [showControls, setShowControls] = useState<boolean>(false);
    const [controlsCountDown, setControlsCountDown] = useState<number>(5);
    const [progress, setProgress] = useState<number>(0);

    const handleCloseVideo = useCallback(() => {
        dispatch(selectVideo(null));
        navigate(`/workout/${id}`);
    }, [dispatch, id, navigate]);

    const handleVideoEnded = useCallback(() => {
        dispatch(selectVideo(currentVideoIndex + 1));
    }, [currentVideoIndex, dispatch]);

    const handleTimeUpdate = useCallback(() => {
        const progress =
            (videoElementsRef.current[currentVideoIndex].current.currentTime /
                videoElementsRef.current[currentVideoIndex].current.duration) *
            100;
        setProgress(progress);
        const nextVideoIndex = currentVideoIndex + 1;
        if (
            nextVideoIndex < videos.length &&
            progress > 80 &&
            !videoElementsRef.current[nextVideoIndex].current.readyState
        ) {
            const nextVideo = videos[nextVideoIndex];
            const nextHls = new Hls();
            nextHls.loadSource(nextVideo.workout_stream_url);
            nextHls.attachMedia(videoElementsRef.current[nextVideoIndex].current);
            prevHlsRef.current = hlsRef.current;
            hlsRef.current = nextHls;
        }
    }, [currentVideoIndex, videos]);

    useEffect(() => {
        const videoElement = videoElementsRef.current[currentVideoIndex].current;
        prevHlsRef.current?.destroy();

        if (Hls.isSupported() && !videoElement.readyState) {
            const hls = new Hls();
            hls.loadSource(videos[currentVideoIndex].workout_stream_url);
            hls.attachMedia(videoElement);
            hlsRef.current = hls;
        }

        videoElement.addEventListener('timeupdate', handleTimeUpdate);
        videoElement.addEventListener('ended', handleVideoEnded);

        return () => {
            videoElement.pause();
            videoElement.currentTime = 0;
            videoElement.removeEventListener('timeupdate', handleTimeUpdate);
            videoElement.removeEventListener('ended', handleVideoEnded);
        };
    }, [currentVideoIndex, handleTimeUpdate, handleVideoEnded, videos]);

    useEffect(() => {
        if (play && videoElementsRef.current[currentVideoIndex].current.paused) {
            videoElementsRef.current[currentVideoIndex].current.play();
        } else {
            videoElementsRef.current[currentVideoIndex].current.pause();
        }
    }, [currentVideoIndex, play]);

    useEffect(() => {
        if (!showCountDown) {
            setControlsCountDown(5);
            setShowControls(true);
            setPlay(true);
        }
    }, [showCountDown]);

    useEffect(() => {
        let intervalId: number;
        if (controlsCountDown > 0) {
            intervalId = window.setInterval(() => {
                setControlsCountDown((prev) => prev - 0.01);
            }, 10);
        }
        if (controlsCountDown < 0) {
            setShowControls(false);
        }
        return () => clearInterval(intervalId);
    }, [controlsCountDown]);

    const handleCloseCountDown = useCallback(() => {
        setShowCountDown(false);
    }, []);

    const handleBackward = useCallback(() => {
        videoElementsRef.current[currentVideoIndex].current.currentTime = Math.max(
            0,
            videoElementsRef.current[currentVideoIndex].current.currentTime - 10 < 0
                ? 0
                : videoElementsRef.current[currentVideoIndex].current.currentTime - 10
        );
    }, [currentVideoIndex]);

    const handleForward = useCallback(() => {
        videoElementsRef.current[currentVideoIndex].current.currentTime = Math.max(
            10,
            videoElementsRef.current[currentVideoIndex].current.currentTime + 10
        );
    }, [currentVideoIndex]);

    const handleLastVideo = useCallback(() => {
        if (currentVideoIndex >= 0) {
            prevHlsRef.current = hlsRef.current;
            dispatch(selectVideo(currentVideoIndex - 1));
        }
    }, [currentVideoIndex, dispatch]);

    const handleNextVideo = useCallback(() => {
        if (currentVideoIndex < videos.length) {
            prevHlsRef.current = hlsRef.current;
            dispatch(selectVideo(currentVideoIndex + 1));
        }
    }, [currentVideoIndex, dispatch, videos.length]);

    const videoControlsProps = useMemo(
        () => ({
            showControls,
            setShowControls,
            controlsCountDown,
            currentVideoIndex,
            progress,
            currentTime: videoElementsRef.current[currentVideoIndex].current?.currentTime,
            play,
            setPlay,
            handleBackward,
            handleForward,
            handleLastVideo,
            handleNextVideo,
        }),
        [
            controlsCountDown,
            currentVideoIndex,
            handleBackward,
            handleForward,
            handleLastVideo,
            handleNextVideo,
            play,
            progress,
            showControls,
        ]
    );

    return (
        <div className='h-dvh font-["Poppins-Med"]'>
            {(showControls || showCountDown) && (
                <div className="absolute top-0 left-0 w-full p-8 z-30 flex">
                    <div className="flex flex-col items-start w-full">
                        <p className="text-white capitalize font-semibold">{videos[currentVideoIndex].trainer_name}</p>
                        <p className="text-white">{videos[currentVideoIndex].title}</p>
                    </div>
                    <div className="absolute top-0 right-0 m-8 z-20 cursor-pointer" onClick={handleCloseVideo}>
                        <CloseIcon fillColor={'none'} />
                    </div>
                </div>
            )}
            <VideoCountDown showPreStart={showCountDown} handleCloseCountDown={handleCloseCountDown} />
            <VideoControls {...videoControlsProps} />
            {videoElementsRef.current.map((video, index) => (
                <VideoPlayer
                    videoRef={video}
                    currentVideoIndex={currentVideoIndex}
                    videoIndex={index}
                    showControls={showControls}
                    setShowControls={setShowControls}
                    setControlsCountDown={setControlsCountDown}
                />
            ))}
        </div>
    );
};

export default WorkoutVideoPage;
