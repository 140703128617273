import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllPrograms, getProgramExercises } from './actions/programActions';

export interface ProgramExercise {
    program_id: number,
    workout_id: number,
    workout_day: number,
    created_at: string,
    updated_at: string,
    workout_title: string,
    workout_description: string,
    workout_difficulty: number,
    workout_mode: number,
    workout_duration: number,
    workout_published: boolean,
    workout_featured: boolean,
    is_favorite: number,
    workout_cover_image: string,
    workout_cover_image_mobile: string
}

export interface Program {
    id: number,
    title: string,
    description: string,
    trainer_id: number,
    program_duration: number,
    status: string,
    published?: boolean,
    featured: boolean,
    published_at: string,
    created_at: string,
    updated_at: string,
    program_difficulty: number,
    order: number,
    cover_image: string
    cover_image_mobile: string
    nutrition_pdf: string
    excercisetrainer_title: string
    trainer_username: string
    exercises: ProgramExercise[]
    currentExcercise?: ProgramExercise
}

export interface ProgramState {
    programs: Program[]
    current?: Program
    loading: boolean
    loadingVideos: boolean
    error: string
}

const initialState: ProgramState = {
    programs: [],
    loading: false,
    loadingVideos: false,
    error: null
};

const programSlice = createSlice({
    name: 'programs',
    initialState,
    reducers: { 
        selectProgram(state, action: PayloadAction<any>) {
            state.current = action.payload.program
        },
        selectVideo(state, action: PayloadAction<any>) {
            state.current.exercises = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPrograms.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getAllPrograms.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.programs = action.payload
                state.error = null
            })
            .addCase(getAllPrograms.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getProgramExercises.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getProgramExercises.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.current.exercises = action.payload
                state.error = null
            })
            .addCase(getProgramExercises.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    }
});



export const { selectProgram, selectVideo } = programSlice.actions;

export default programSlice.reducer;
