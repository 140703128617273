import { Link } from 'react-router-dom';
import BackIcon from '../assets/img/icons/back-icon.png';
import ICLogo from '../assets/img/logo.png';
import AppLogo from '../assets/img/logotype.png';

const Hero = (props: { prevPath?: string }) => {
    const { prevPath } = props;
    return (
        <div className="h-full flex justify-center">
            <div className="h-full flex items-center w-2/3">
                {prevPath && (
                    <Link to={prevPath} className="absolute left-10 top-10">
                        <div className="bg-black rounded-full">
                            <img alt="back" src={BackIcon} className="w-18" />
                        </div>
                    </Link>
                )}
                <div className="flex flex-col items-center text-center">
                    <img alt="sweatsonic" src={ICLogo} className="w-1/3" />
                    <img alt="sweatsonic" src={AppLogo} className="w-full" />
                    <div className="divider divider-secondary my-0" />
                    <span className="text-sm">The music is your trainer</span>
                </div>
            </div>
        </div>
    );
};
export default Hero;
