import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { selectVideo, WorkoutVideo } from '../redux/workoutSlice';

const VideoCarousel = (props: { videos: WorkoutVideo[]; nextVideoIndex }) => {
    const dispatch = useDispatch();
    const { videos, nextVideoIndex } = props;
    const { loadingVideos } = useSelector((state: RootState) => state.workouts);
    const carouselRef = useRef<HTMLDivElement>(null);

    const handleSelectVideo = useCallback(
        (index: number) => {
            dispatch(selectVideo(index));
        },
        [dispatch]
    );

    useEffect(() => {
        if (carouselRef.current && videos.length > 0) {
            const carousel = carouselRef.current;
            const itemWidth = carousel.scrollWidth / videos.length;
            const scrollPosition = itemWidth * nextVideoIndex;
            carouselRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' });
        }
    }, [nextVideoIndex, videos.length, carouselRef.current?.scrollWidth]);

    return (
        <div className="w-full flex flex-col justify-center items-center">
            <div className="flex items-center justify-between px-6 text-white w-full lg:w-5/6">
                <p className="text-xl">UP NEXT</p>
                <p className="text-xl">{nextVideoIndex + '/' + videos.length}</p>
            </div>
            <div ref={carouselRef} className="carousel w-full lg:w-full h-28 space-x-2 py-4">
                {loadingVideos ? (
                    <div className="h-full w-full pl-4 flex">
                        <div className="skeleton bg-secondary/10 w-2/3 h-full"></div>
                        <div className="skeleton ml-4 bg-secondary/10 w-2/3 h-full"></div>
                    </div>
                ) : (
                    videos.map((video, index) => (
                        <div
                            key={index}
                            className="carousel-item w-2/4 md:w-1/6 rounded-lg bg-black/75 border border-opacity-25 cursor-pointer"
                        >
                            <div onClick={() => handleSelectVideo(index)} className="card flex-row relative p-4">
                                <img
                                    className="rounded w-1/3 object-cover"
                                    src={video?.video_thumbnail_image}
                                    alt="workout"
                                />
                                <div className="p-2 flex items-center">
                                    <p className="text-xs">{video.title}</p>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default VideoCarousel;
