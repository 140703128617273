import Header from 'components/Header';
import ProgramsCarousel, { ProgramsCarouselProps } from 'components/ProgramsCarousel';
import Tabs from 'components/Tabs';
import WorkoutsCarousel, { WorkoutCard } from 'components/WorkoutCarousel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { getAllPrograms } from '../redux/actions/programActions';
import { getAllTrainers } from '../redux/actions/trainerActions';
import { featuredWorkouts } from '../redux/actions/workoutActions';

const HomePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [active, setActive] = useState('discover');
    const [workoutsData, setWorkoutsData] = useState([]);
    const [programsData, setProgramsData] = useState([]);
    const { loading: loadingWorkouts, workouts } = useSelector((state: RootState) => state.workouts);
    const { loading: loadingPrograms, programs } = useSelector((state: RootState) => state.programs);
    const { loading: loadingTrainers, trainers } = useSelector((state: RootState) => state.trainers);
    const [page] = useState(1);

    useEffect(() => {
        dispatch(featuredWorkouts());
        dispatch(getAllPrograms());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllTrainers({ page, take: 10 }));
    }, [dispatch, page]);

    useEffect(() => {
        setWorkoutsData(
            workouts.map((workout) => {
                return {
                    id: workout.id,
                    title: workout.title,
                    image: workout.workout_cover_image,
                    description: {
                        difficulty: workout.difficulty,
                        duration: workout.workout_duration,
                    },
                };
            })
        );
        setProgramsData(
            programs.map((program) => {
                return {
                    id: program.id,
                    title: program.title,
                    image: program.cover_image,
                    description: {
                        difficulty: program.program_difficulty,
                        duration: program.program_duration,
                    },
                };
            })
        );
    }, [programs, workouts]);

    const handleSelectWorkout = (element: WorkoutCard) => {
        navigate('/workout/' + element.id);
    };

    const handleSelectProgram = (element: WorkoutCard) => {
        navigate('/program/' + element.id);
    };

    const programsProps: ProgramsCarouselProps = {
        title: 'Featured Programs',
        elements: programsData,
        handleSelectElement: handleSelectProgram,
        loading: loadingPrograms,
    };
    const workoutProps: ProgramsCarouselProps = {
        title: 'Featured Workouts',
        elements: workoutsData,
        handleSelectElement: handleSelectWorkout,
        loading: loadingWorkouts,
    };

    return (
        <div className="h-full w-full flex flex-col">
            <div className="w-full h-1/6 flex flex-col justify-between">
                <div className="flex items-center w-full h-full">
                    <Header />
                </div>
                <Tabs active={active} setActive={setActive} />
            </div>
            {active === 'discover' ? (
                <div className="h-full overflow overflow-y-scroll mb-16 lg:mb-0">
                    <WorkoutsCarousel {...workoutProps} />
                    <ProgramsCarousel {...programsProps} />
                    {/* <WorkoutsCarousel {...workoutProps} /> */}
                    {/* <ProgramsCarousel {...programsProps} /> */}
                </div>
            ) : (
                <div className="h-full overflow overflow-y-scroll mb-16 lg:mb-0">
                    <input
                        type="text"
                        placeholder="Search"
                        autoComplete="off"
                        className="input input-bordered rounded-full focus:outline-secondary w-full"
                    />
                    <div className="w-full grid grid-cols-2 gap-4 p-2 lg:grid-cols-4">
                        {loadingTrainers ? (
                            <>
                                <div className="skeleton h-72 px-2 bg-secondary/10"></div>
                                <div className="skeleton h-72 px-2 bg-secondary/10"></div>
                                <div className="skeleton h-72 px-2 bg-secondary/10"></div>
                                <div className="skeleton h-72 px-2 bg-secondary/10"></div>
                            </>
                        ) : (
                            trainers.map((trainer, index) => (
                                <div
                                    key={index}
                                    className="relative rounded h-72 card flex justify-end items-center"
                                    style={{
                                        backgroundImage: `url(${trainer.cover_image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'top',
                                    }}
                                >
                                    <div className="absolute w-full h-full inset-0 bg-gradient-to-t from-black to-transparent z-0"></div>
                                    <p className="capitalize text-center p-4 z-10">{trainer.username}</p>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default HomePage;
