import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const VideoCountDown = (props: { showPreStart: boolean; handleCloseCountDown: () => void }) => {
    const { showPreStart, handleCloseCountDown } = props;
    const [countdown, setCountdown] = useState(15);
    const { workout } = useSelector((state: RootState) => state.workouts);

    useEffect(() => {
        let intervalId: number;
        if (countdown > 0) {
            intervalId = window.setInterval(() => {
                setCountdown(countdown - 0.01);
            }, 10);
        }
        if (countdown < 0) {
            handleCloseCountDown();
        }
        return () => clearInterval(intervalId);
    }, [countdown, handleCloseCountDown]);

    return (
        <div
            className={`absolute w-full h-dvh z-10 cursor-pointer flex flex-col items-center justify-evenly ${showPreStart ? '' : 'hidden'}`}
        >
            <img
                src={workout.workout_cover_image}
                alt="cover-image"
                className="absolute object-cover blur-md h-full z-10"
            />
            <div className="relative flex flex-col items-center justify-center h-72 w-72 z-10">
                <svg className="absolute inset-0 transform w-72 h-72">
                    <circle
                        cx="145"
                        cy="145"
                        r="120"
                        stroke="currentColor"
                        strokeWidth="10"
                        fill="transparent"
                        strokeDasharray={Math.PI * 2 * 120}
                        strokeDashoffset={Math.PI * 2 * 120 + (countdown / 15) * (Math.PI * 2 * 120)}
                        className="text-secondary"
                    />
                </svg>
                <p>Starting in...</p>
                <span className="text-4xl text-white countdown">
                    {'00:' + Math.round(countdown).toString().padStart(2, '0')}
                </span>
            </div>
            <div className="z-10">
                <button
                    onClick={handleCloseCountDown}
                    className="btn btn-circle btn-outline btn-wide bg-black/75 text-white tracking-wider font-light"
                >
                    Start Now
                </button>
                <div className="flex w-full justify-evenly p-4">
                    <div className="bg-black/50 px-2 border border-white rounded-full flex justify-center items-center font-semibold w-fit">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.583496 8.50456C0.583496 4.13493 4.13683 0.583313 8.50016 0.583313C12.8635 0.583313 16.4168 4.13493 16.4168 8.50456C16.4168 12.865 12.8635 16.4166 8.50016 16.4166C4.13683 16.4166 0.583496 12.865 0.583496 8.50456ZM11.404 9.30578C11.488 9.22176 11.595 9.09192 11.6179 9.06137C11.7402 8.90097 11.8013 8.70238 11.8013 8.50456C11.8013 8.2823 11.7325 8.07608 11.6026 7.90804C11.592 7.8974 11.5711 7.87471 11.544 7.84514C11.4931 7.78982 11.4202 7.7104 11.3505 7.64072C10.7239 6.96858 9.08856 5.86873 8.23271 5.53266C8.1028 5.47996 7.77421 5.36463 7.59846 5.35699C7.43034 5.35699 7.26987 5.39518 7.11704 5.47156C6.926 5.57849 6.77317 5.74652 6.68911 5.94511C6.63562 6.08259 6.55156 6.49504 6.55156 6.50267C6.46751 6.95331 6.42166 7.68655 6.42166 8.49616C6.42166 9.26835 6.46751 9.97027 6.53628 10.4285C6.53817 10.4304 6.54474 10.4632 6.55496 10.5141C6.586 10.6689 6.65068 10.9914 6.71968 11.1236C6.88779 11.4444 7.21638 11.643 7.56789 11.643H7.59846C7.8277 11.6353 8.30912 11.4367 8.30912 11.4291C9.11913 11.093 10.7162 10.0467 11.3581 9.3516L11.404 9.30578Z"
                                fill="white"
                            />
                        </svg>
                        <p className="px-2">{workout.workout_duration % 60} min</p>
                    </div>
                    <div className="bg-black/50 px-2 border border-white rounded-full flex justify-center items-center font-semibold w-fit">
                        <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.691 10.9889C13.4854 8.3914 12.2394 6.76366 11.1402 5.32726C10.1223 3.99747 9.24322 2.84913 9.24322 1.15516C9.24322 1.01909 9.16471 0.894722 9.04022 0.832369C8.91535 0.769642 8.76531 0.779915 8.65179 0.859991C7.0009 2.00656 5.62345 3.93903 5.14228 5.78289C4.80824 7.06658 4.76405 8.50971 4.75784 9.46283C3.23327 9.14678 2.88791 6.93337 2.88426 6.90925C2.86709 6.79445 2.79482 6.69454 2.68968 6.63998C2.58345 6.58613 2.45857 6.58221 2.35087 6.63395C2.27093 6.67151 0.388546 7.59983 0.279022 11.3063C0.271346 11.4297 0.270996 11.5533 0.270996 11.677C0.270996 15.2779 3.28983 18.2078 7.00016 18.2078C7.00528 18.2081 7.01075 18.2089 7.01513 18.2078C7.0166 18.2078 7.01804 18.2078 7.01986 18.2078C10.7211 18.1975 13.7293 15.2716 13.7293 11.677C13.7293 11.4959 13.691 10.9889 13.691 10.9889ZM7.00016 17.4822C5.76326 17.4822 4.75711 16.4419 4.75711 15.1632C4.75711 15.1196 4.75676 15.0756 4.76002 15.0218C4.77498 14.4825 4.88051 14.1144 4.99624 13.8695C5.21311 14.3216 5.60081 14.7373 6.23058 14.7373C6.43723 14.7373 6.60444 14.575 6.60444 14.3744C6.60444 13.8579 6.61541 13.2619 6.74792 12.724C6.86586 12.2471 7.14768 11.7397 7.50475 11.3329C7.66355 11.8609 7.97316 12.2882 8.27544 12.7052C8.70807 13.3019 9.15528 13.9188 9.23379 14.9708C9.23852 15.0331 9.24329 15.0958 9.24329 15.1632C9.24322 16.4418 8.23707 17.4822 7.00016 17.4822Z"
                                fill="white"
                            />
                        </svg>
                        <p className="px-2">{workout.difficulty}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoCountDown;
