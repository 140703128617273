import { createAsyncThunk } from '@reduxjs/toolkit';
import { connection } from 'util/axios';

export const getPrices = createAsyncThunk(
    'stripe/prices',
    async (_, { rejectWithValue }) => {
        try {
            const response = await connection.get('/stripe/prices');
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);